.iconButton{
  font-size: 17px !important;
  font-weight: normal;
  color: var(--primary) !important;
  &:hover {
    background-color: transparent !important;
  }

  svg {
    margin-right: 10px;
    margin-bottom: 2px;
  }
}
