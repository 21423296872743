.dropdownMenu {
  padding-top: 0;
  padding-bottom: 0;
}

.menuItem {
  padding: 3px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--background-grey) !important;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 14px;
  }

  & * {
    color: var(--grey)
  }

  &.link, &.button {
    color: var(--grey) !important;

    &:hover {
      svg, path {
        color: var(--primary) !important;
      }

      color: var(--primary) !important;
      text-decoration: underline;
    }

  }
}
