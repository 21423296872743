.footer {
  display: flex;
  flex-direction: column;
  background-color: #75787B;
  padding-top: 16px;

  &, * {
    color: var(--white);

  }

  a {
    font-size: 14px;
  }

  .links {
    display: flex;
    flex-wrap: wrap;

    > div:not(.social) {
      flex: 1;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin: 10px 0;
      }
    }
  }

  .social {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > * {
      margin-left: 10px;

      svg {
        box-shadow: 0 0 3px var(--white);
        border-radius: 50%;
      }
    }
  }

  .bottom {
    background-color: #212B36;

    .bottomContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      padding-bottom: 15px;
      p {
        margin: 0;
        display: flex;
        align-items: center;
      }

      .bottomLinks {
        display: flex;
        p{
          margin: 0 5px;
        }
      }
    }
  }
}
