.backButton{
  background: none;
  border:none;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 18px;
  margin: 10px 0 20px 0;
  color: var(--heading);

  &:hover{
    text-decoration: underline;
  }
}

.backButtonChevron{
  margin-bottom: 2px;
}
