.report-management__header {
  border-bottom: 1px solid var(--primary);
  padding-bottom: 20px;
}
.report-page{
  position: relative;
}
.report-management__filters {
  width: 100%;
  > * {
    margin: 20px 0;
  }
}
.report-management__actions {
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
}
