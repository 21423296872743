.EditableField{
  &:focus{
    -webkit-box-shadow: none;
    box-shadow: none;
    outline:none;
  }
}
.missingData{
  color: var(--grey);
  font-style: italic;
  visibility: hidden;
}

.editableTextArea {
  resize: horizontal;
  width: 100%;
}

.textContent {
  line-height: 25px;
}
