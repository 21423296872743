body {
    margin: 0;
    font-family: 'Circe', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
}

body * {
    font-family: 'Circe', sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 15px 30px;
}
