.tabbed-details {
  .tabbed-details__edit{
    padding: 20px 0 0 20px;
  }
  .tab-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .tab-info-box {
    width: 100%;
    max-width: 800px;

    > div {
      .tab-info-box__label {
        font-weight: bolder;
        margin-bottom: 3px;
      }

      > *:not(.tab-info-box__label) {
        flex: 1;
        margin-bottom: 15px;
      }
    }
  }

  tbody{
    .tabbed-details__delete-row-cell{
      text-align: right;
      padding-right: 0;
    }
  }
}
