.editButtons {
  display: flex;
  button:focus {
    outline: 0;
  }

  &.toLeft{
    flex-direction: row-reverse;
  }

  > button {
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 5px;
    color: var(--grey) !important;

    button {
      padding: 5px;
    }
  }
}
