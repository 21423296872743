.searchBox{
  .searchTextField {
    div fieldset {
      border-color: var(--primary) !important;
      border-width: 2px;
    }

    div:hover fieldset {
      border-color: var(--dark-primary) !important;
      border-width: 2px;
    }

    div.MuiInputBase-root {
      font-family: Circe-regular;
    }
  }
}
