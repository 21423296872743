.validateButtons {
  display: flex;
  margin-top: 20px;
  :first-child{
    margin-right: 10px;
  }

}

.rejectReasonInput {
  width: 100%;
}
.validateAllButton{
  margin: 20px 0 0 20px !important;
}
.cbField{
  display: flex;
  flex-direction: column;
}
