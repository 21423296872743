.details-page {
  .details-page__tab-content {
    &.details-page__tab-content--single{
      .details-page__tab-content__tabs{
        display: none;
      }
    }

    .details-page__tab-content__tabs {
      button {
        flex: 1;
        max-width: 300px;
      }
    }
  }
}
