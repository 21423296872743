.infoBox {
  .content {
     * {
       font-size: 15px;
     }
    display: flex;
    ul {
      list-style: none;
      flex: 1;
      margin: 15px;
      padding: 0;
    }

    .entryList {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .entryPair {
        padding: 5px 0;
      }
    }
  }


}
