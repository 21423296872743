@import "src/views/PublicDirectory/PublicDirectory.module";

$site-card-vertical-padding: 20px;

.siteCard {
  @extend .public-directory-card;
  margin-bottom: 25px;

  .dataItem {
    display: flex;
    align-items: flex-start;

    p{
      margin: 0;
      &:first-of-type {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  .top {
    display: flex;
    padding: $site-card-vertical-padding* 2  0;

    .left {
      flex: 1;
      .dataItem{
        margin-top: 15px;
      }
    }

    .right {
      display: flex;
      align-items: flex-end;
    }
  }

  .bottom {
    display: flex;
    border-top:  1px solid rgba(0,0,0,.125);
    padding: $site-card-vertical-padding 0;
    > * {
      width: 25%;
      display: flex;
      justify-content: center;
    }
  }
}
