.actions {
  display: flex;
  flex-wrap: wrap;

  button {
    &.button {
      flex: 1;
      min-width: 180px;
      white-space: nowrap;
      margin-top: 10px;

    }
  }
}
