.tableToolbar{
  display: flex;
  justify-content: space-between;
  padding: 20px 16px !important;

  > *:not(.actions){
    flex: 1;
    margin-right: 10px;
    max-width: 40%;
  }
  .actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: auto;
  }
}
