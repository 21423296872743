.accordion-tables{
  min-width: 700px;

  .accordion-table {
    display: flex;

    .accordion-table__content{
      width: 100%;
      .accordion-table__content__no-expand{
        cursor: inherit;
      }
    }
    &:not(:last-child){
      margin-bottom: 30px;
    }
    .accordion-table__delete-row{
      align-self: flex-start;
      margin-left: auto;
    }
    &.expanded .accordion-table__content{
      background-color: var(--background-grey);
      .MuiAccordionSummary-root{
        border-bottom: 1px solid var(--grey);
      }
    }
    .MuiPaper-root{
      background-color: transparent;
      .MuiAccordionSummary-content{
        margin:0;

      }
    }
    .visible{
      display: flex;
      margin: 5px 0;
      width: 100% ;
      li{
        flex: 1;
        &:not(:last-of-type){
          margin-right: 20px;
        }
        p:first-of-type{
          font-weight: bolder;
          margin-bottom: 10px;
        }
      }
    }
    .hidden{
      padding-top: 15px;
      li{
        &:not(:last-of-type){
          margin-bottom: 15px;
        }
        p:first-of-type{
          font-weight: bolder;
          margin-bottom: 5px;
        }
      }
    }
    ul {
      list-style: none;
    }
    p{
      margin: 0;
    }
  }

}
