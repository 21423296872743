.accordionContent{
  width: 100%;
  display: flex;
  flex-direction: column;

  .divider {
    margin: 1rem 0;
    &:last-child {
      display: none;
    }
  }
}
