.audit-detail {
  .audit-details__main {
    display: flex;
    padding-bottom: 30px;
    .audit-details__main__details{
      display: flex;
      flex-direction: column;
      padding-right: 50px;
      flex: 1;
    }
    .audit-details__main__details__sync-row{
      display: flex;
      padding-right: 10px;
      button{
        width: 20px;
        height: 20px;
      }
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd > fieldset {
    border: none !important;
  }

  .searchable-dropdown .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-left: 0px;
  }

  .searchable-dropdown .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd > input {
    border-bottom: 1px solid grey;
    padding-left: 0px !important;
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl::after {

    border-bottom: 2px solid var(--primary) !important;
  }
}

