/* ----------------------------------------- STYLING COMMON CLASSES */

/* COLOURS - section 2.2.2 of D0100 User-interface guidelines document */

:root {
    --primary: #ea7600;
    --dark-primary: #FC4C02;
    --secondary: #FC4C02;
    --black: #000000;
    --grey: #75787B;
    --white: #FFFFFF;
    --background-grey: #F2F2F2;
    --disabled: #DDDDDD;
    --red: #DC4405;
    --danger: #DC4405;
    --green: #64A70B;
    --success: #64A70B;
    --heading: #212b36;
    --label: #75787B;
    --transparent-primary: #ffdcb9;
    --box: #fcfcfc;
}

/* TYPOGRAPHY - section 2.2.1 of D0100 User-interface guidelines document*/

@font-face {
    font-family: "Circe";
    src: url(../fonts/circe_light.otf) format("opentype");
    font-weight: 200;
}

@font-face {
    font-family: "Circe";
    src: url(../fonts/circe_regular.otf) format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Circe";
    src: url(../fonts/circe_bold.otf) format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Circe";
    src: url(../fonts/circe_extra_bold.ttf) format("truetype");
    font-weight: 800;
}
h1, h2, h3,h4,h5,h6{
    color: var(--heading);
    margin: 0 0 0.5rem 0;

}
h1 {
    font-size: 20px;
}

h2 {
    font-size: 18px;
}

h3, th {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

p {
    font-size: 16px;
    line-height: 1.15;
    color: var(--heading);
    margin: 0 0 1rem 0;
}

button, input, select, textarea{
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

label {
    font-size: 16px;
    color: var(--black);
}
.colorRed{
    color: red;
}
.disabledMenuItem{
    opacity: 0.5;
    cursor: default;    
}

    .tab{
        background-color: var(--white) !important;
    }
    .tab.Mui-selected{
        color: var(--primary) !important;
    }

    .dot {
        height: 25px;
        width: 25px;
        background-color: var(--dark-primary);
        color: var(--white);
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        line-height: 1.85 !important;
        margin-right: 10px;
      }

/* DISTANCES */

.private{
    display: flex;
}
.private > :not(nav){
    flex: 1;
    padding-top: 25px;
}

.paddingTop {
    padding-top: 20px;
}
.fullWidth {
    width: 100%;
}



/* ------- */


/* ----------------------------------------- COMPONENTS COMMON CLASSES */
/* ACCORDION */

.add{
    font-size: 16px;
    font-weight: normal;
    color: var(--primary);
    margin-left: 10px;
    line-height: 0.85;
}

.add:hover{
    color: var(--dark-primary);
    cursor: pointer;
    background: none !important;
    text-decoration: none !important;

}

.MuiButton-root {
    text-transform: none !important;
}


/* ------- */

/* CARDS */
.MuiPaper-rounded {
   /* border-radius: 20px !important;*/
}

.MuiPaper-elevation1 {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.MuiExpansionPanel-rounded {
    box-shadow: none;
}

 /* ------- */

/* INPUT FIELDS */

form{
    width: 50%;
}

.input fieldset{
    border-radius: 15px;
}

.MuiOutlinedInput-adornedEnd fieldset {
    border-radius: 15px;
}

.input:not(.checkbox)   {
    width: 100%;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--grey) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: var(--grey) !important;
}

.select .MuiInputBase-formControl:focus {
    outline: none !important;
}

/* ------- */

/* DATE FIELDS */

.date-field{
    min-width: 170px !important;
}
.date-field div div button span svg{
    fill: var(--primary);
}

.MuiPickersDay-daySelected{
    background-color: var(--primary) !important;
}

.date-field div.Mui-focused fieldset{
    border-color: var(--primary) !important;
    border-width: 2px;
}

.date-field .PrivateNotchedOutline-legendLabelled-14 > span{
    padding-right: 10px;
}

.date-field label {
    padding-right: 8px;
}

.date-field label.Mui-focused{
    color: var(--grey);
}
.date-field .MuiPaper-rounded {
    left: 425px !important;
}


/* ------- */

/* DIALOG BOXES */

.MuiDialog-paperWidthSm {
    border-radius: 20px !important;
}
.MuiDialogTitle-root {
    color: #212B36 !important;
    font-weight: bold !important;
}

.MuiDialogContentText-root {
    font-size: 16px !important;
    color: #737980 !important;
}

.MuiDialogActions-root {
    padding: 8px 8px 8px 3% !important;
    text-align: left !important;
    display: block !important;
}

.MuiDialogActions-spacing {
    margin-bottom: 10px;
}

.SuspendDialogContent > * {
    margin: 15px 0 !important;
}

/* ------- */

/* DROPDOWNS */

.MuiExpansionPanelSummary-root.Mui-expanded {
    max-height: 30px !important;
    min-height: 30px !important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
    max-height: 30px !important;
    min-height: 30px !important;
}

.MuiFormControlLabel-root {
    margin-bottom: 0 !important;
}


/* HEADERS */


.responsive {
    width: 100%;
    height: auto;
}

button[aria-controls=simple-menu] span.MuiButton-label {
    font-size: 16px;
    color: var(--grey);
}

/* ------- */

/* LINKS */

a{
    color: var(--primary);
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

/* ------- */

/* LISTS */

.MuiAccordionDetails-root > .listContents > div:not(:only-child):not(:last-child) {
    margin-bottom: 30px;
}

.listContents .header {
    margin-bottom: 0;
}

/* ------- */


/* ------- */

/* ------- */
/* TABS */

.PrivateTabIndicator-colorSecondary-3 {
    background-color: var(--primary) !important;
}
.MuiTabs-indicator {
    background-color: var(--primary) !important;
}


/* ------- */

/* TEXT FIELDS */
.MuiOutlinedInput-input {
    padding-left: 20px !important;
}

.PrivateNotchedOutline-root-12 {
    padding: 0px 15px !important;
    border-color: var(--grey) !important;
}
.text-field div textarea {
    color: var(--heading);
}

/* Inactive State (no input) */
.text-field label {
    color: var(--grey);
}

/* Inactive State (input) */
.text-field div input {
    color: var(--black);
}
.text-field div fieldset {
    border-color: var(--grey)
}

/* Hover State */
.text-field div:hover fieldset {
    border-color: var(--heading)
}

/* Active State */
.text-field div.Mui-focused fieldset{
    border-color: var(--primary) !important;
    border-width: 2px;
}
.text-field label.Mui-focused{
    color: var(--grey);
}

/* Hover State Readonly*/
.text-field.read-only div.Mui-focused fieldset {
    border-color: var(--grey) !important;
    border-width: 1px;

}

/* Optional text field/ Help Text*/
.text-field p.MuiFormHelperText-root {
    color: var(--grey);
    font-weight: 200;
    font-size: 14px
}

/* Text Field Error */
.text-field div.Mui-error fieldset{
    border-color: var(--red);
    border-width: 2px;
}
.text-field label.Mui-error{
    color: var(--grey);
}

.clickableClear {
    cursor: pointer;
}


/* Inactive State (no input) */
.text-field label {
    color: var(--grey);
}

/* Inactive State (input) */
.text-field div input {
    color: var(--black);
}
.text-field div fieldset {
    border-color: var(--grey)
}

/* Hover State */
.text-field div:hover fieldset {
    border-color: var(--heading)
}

/* Active State */
.text-field div.Mui-focused fieldset{
    border-color: var(--primary) !important;
    border-width: 2px;
}
.text-field label.Mui-focused{
    color: var(--grey);
}

/* Hover State Readonly*/
.text-field.read-only div.Mui-focused fieldset {
    border-color: var(--grey) !important;
    border-width: 1px;
}

/* Optional text field/ Help Text*/
.text-field p.MuiFormHelperText-root {
    color: var(--grey);
    font-weight: 200;
    font-size: 14px
}

/* Text Field Error */
.text-field div.Mui-error fieldset{
    border-color: var(--red);
    border-width: 2px;
}
.text-field label.Mui-error{
    color: var(--grey);
}
.text-field p.MuiFormHelperText-root.Mui-error {
    color: var(--red);
}
/* ------- */

/* SELECTS */
.select label.MuiInputLabel-root{
    color: var(--grey);
}

/* Inactive State (no input) */
.select label {
    color: var(--grey);
}

/* Inactive State (input) */
.select div div.MuiSelect-select {
    color: var(--heading);
}
.select div fieldset {
    border-color: var(--grey)
}

/* Hover State*/
.select div:hover fieldset {
    border-color: var(--heading) !important;
    border-width: 1px;
}

/* Active State */

.select div.Mui-focused fieldset{
    border-color: var(--primary) !important;
    border-width: 2px;
}

.select label.Mui-focused{
    color: var(--grey);
}


.MuiSelect-select:focus {
   background: none !important;
}

/* ------- */

/* -----------------------------------------TABLE COMMON CLASSES */

ul.MuiPagination-ul {
    justify-content: center;
    padding-top: 5px;
}

.filter-row {
    box-shadow: none !important;
    background-color: var(--white) !important;
    border-top: 1px solid var(--disabled) !important;
}

.country-search div div label {
    background-color: var(--white) !important;
    padding-right: 10px;
}

/* ------- */


/* -----------------------------------------GENERAL ACCORDION CLASSES */


.detailsSection {
    columns: 2;
}


.sync-button {
    padding: 0 !important;
    margin-right: 0px !important;
}

.sync-button > span {
    margin-right: 0px !important;
}

.sync-button > span > svg {
    margin-right: 0px !important;
}

.detailsSection > .listEntry {
    display: inline-block
}

/* -----------------------------------------CREATE/EDIT AUDITOR CLASSES */

.accordion-input .input, .accordion-input .date-field {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.accordion-input > h3 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: normal;
}

.accordion-input > h6 > .MuiSvgIcon-root {
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
}

.auditor-standard-section {
    display: flex;
    align-items: center;
    margin-top: 3rem !important;
}

.auditor-standard-section > .MuiSvgIcon-root {
    margin: 0rem 0rem 1rem 1rem;
}

.auditor-standard-section > div {
    width: 100%;
}

.auditor-standard-section > div > .input {
    margin-bottom: 1rem;
}

.auditor-standard-section > .MuiAccordion-root > .MuiAccordionSummary-root > .MuiAccordionSummary-content {
    flex-direction: column-reverse;
}

.category-accordion > .MuiAccordionSummary-root > .MuiAccordionSummary-content > div {
    width: 100%;
}

.category-accordion > .MuiAccordionSummary-root > .MuiAccordionSummary-content > div > .select > .MuiInputBase-root > .MuiSelect-selectMenu {
    white-space: normal;
}

/* -----------------------------------------AUDITS */

.required-text {
    color: var(--red);
    font-size: 14px;
}

/* -----------------------------------------FORMS */

.validator-form {
    width: 100%;
    margin-bottom: 1.5em;
}

.validator-form > div > .button,
.validator-form > div > div > .button {
    margin-top: 1.5em;
}

.validator-form > div > .button:not(:last-child),
.validator-form > div > div > .button:not(:last-child) {
    margin-right: 0.5em;
}
.verifycityfilter
{
    border-bottom-color:'gray';
    border-bottom-width:1px;
    border-top: none;
    border-left:none;
    border-right:none;
    margin-top:9px;
    outline-style:none;

}
