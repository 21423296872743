.searchTableContent{
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  > :not(:first-child){
    flex: 1;
  }
  .filter{
   padding-right: 25px;
  }
  .certificationBodyFilter{
    width: 100%;
    fieldset {
      border-radius: 5px;
    }
  }
}
