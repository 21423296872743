@import "../../css/utils/mediaQueries";
$public-directory-filter-margin: 30px;

@include min-tablet{
  .publicDirectoryTabs{
    button{
      width: 300px;
    }
  }
}

.isLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.publicDirectory {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  position: relative;


  .search {
    @include min-tablet{
      margin-left: (300px + $public-directory-filter-margin);
      max-width: 500px;
    }
  }

  .sort {
    font-size: 18px;
    padding: 0;
    width: auto;
    margin-left: auto;
  }

  .main {
    display: flex;
    @include min-tablet{
      .filters {
        margin-right: $public-directory-filter-margin;
      }
    }
    .content {
      flex: 1
    }
  }
}

.public-directory-card {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 20px;
  padding: 0 25px;

}
