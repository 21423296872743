@import "../../../../css/utils/mediaQueries";


.header {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  align-items: center;
  padding: 16px 0;
  flex-wrap: wrap;
  width: 100%;

  .logo {
    display: flex;
    order: 1;
  }

  .menuItems {
    list-style: none;
    padding: 0 8px;
    margin: 0;
    display: flex;
    order: 3;
    width: 100%;
    flex-direction: column;
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 0.2s;
    li{
      padding: 8px 0;
    }

    &.open {
      max-height: 300px;
    }
    a {
      color: var(--heading);

      &:hover {
        color: var(--primary);
      }
    }

  }

  .toggleMenu {
    order: 2;
    margin-left: auto;
  }
  @include min-tablet{
    flex-wrap: nowrap;
    .menuItems{
      flex-direction: row;
      max-height: none;
      li {
        display: flex;
        align-items: center;
        padding: 0 8px;
      }
      .login {
        margin-left: auto;
        padding: 0;
      }
    }
    .toggleMenu{
      display: none;
    }
  }
}
