.file-drop-zone {
  .MuiDropzoneArea-root {
    background-color: var(--background-grey);
    border: dotted;
    border-color: var(--primary);
    position: relative;

    &:focus{
      outline: none;
    }

    .MuiDropzoneArea-textContainer {
      .MuiTypography-h5, .MuiDropzoneArea-icon {
        color: var(--grey) !important;
      }
    }
  }
}


