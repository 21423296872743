.menuSpacing {
  width: 50px;
  max-width: 50px;

  &.open {
    min-width: 200px;
    max-width: 200px;
  }
}

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  padding-top: 15px;
  box-shadow: 2px 2px 3px 4px var(--background-grey);

  .toggleButton {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-self: center;

    &:focus {
      outline: none;
    }
  }

  &.open {
    .toggleButton {
      margin-right: 15px;
      align-self: flex-end;
    }

    .content {
      display: flex;
    }
  }

  .content {
    display: none;
    flex-direction: column;
  }

  h1, h2 {
    padding: 0 30px;
    margin: 0;
    text-align: center;
  }

  .account {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;

    button {
      background: none;
      border: none;
      outline: none;

      &:focus {
        outline: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      align-self: center;
      font-size: 40px;
    }

    p {
      :first-of-type {
      }

      margin: 0;
    }

    padding: 0 15px 15px;
    border-bottom: 1px solid var(--background-grey);
  }

  * {
    color: var(--white);
    box-sizing: border-box;
  }

  .link {
    text-align: left;
    padding: 10px 10px 10px 20px;

    &.separated{
      border-top: 1px solid var(--background-grey);
    }

    &:hover, &:focus {
      color: var(--white) !important;
    }

    &.activeLink {
      background-color: rgb(238, 150, 79);
    }
  }
}
