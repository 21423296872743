.dateRangePicker {
  display: flex;
  flex-direction: column;

  .pickers {
    display: flex;
    justify-content: space-between;
     > * {
       flex: 1;
     }
  }

  .noLabel{
    > * {
      margin-top: 0 !important;
    }
  }
}
