$box-padding-horizontal: 20px;

.box {
  display: flex;
  flex-direction: column;

  .titleRow {
    display: flex;
    padding: 0 $box-padding-horizontal 4px;
    height: 50px;

    h2 {
      display: flex;
      align-items: flex-end;
      margin: 0;
      vertical-align: middle;
    }

    .actions {
      margin-left: auto;
      display: flex;
      align-items: flex-end;
    }
  }

  .children {
    border: 1px solid var(--background-grey);
    background-color: var(--box);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    &.childPadding{
      padding: 15px $box-padding-horizontal;
    }
  }
}
