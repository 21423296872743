.checkbox {
         display: flex;
  align-items: center;
           p{
             margin: 0;
           }
}

.main {
  max-width: 600px;
  p {
    margin: 0;
  }
}
.selectedStatus{
  color: var(--primary);
}

.summary {
  display: flex;
  justify-content: space-between;
  > * {
     justify-content: space-between;
  }
}

.details{
  flex-direction: column;
}

.divider {
  margin: 10px 0 !important;
}
.action {
  margin-top: 20px !important;
}

.root{
  position: relative;
}

.loadingOverlay{
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
