.button.MuiButton-root {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  text-transform: none;
  padding: 10px 60px;
  border-radius: 50px;
  box-shadow: none;
  max-height: 40px !important;
  border-width: 2px;


  &.primary:not(:disabled){
    background-color: var(--primary) !important;
    color: var(--white);
    &:hover{
      background-color: var(--dark-primary) !important;
    }
  }

  &.secondary:not(:disabled){
    background-color: var(--white);
    color: var(--primary);
    border-color:var(--primary);
    &:hover{
      background-color: var(--transparent-primary) !important;
      outline-color: var(--primary) !important;
    }

  }

  :focus{
    outline: none;
  }
}
