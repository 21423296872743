@import "../../css/utils/mediaQueries";
.FilterGroup {

  @include min-tablet{
    min-width: 300px;
    width: 300px;
  }


  span {
    display: flex;
  }

  hr {
    margin-bottom: 10px;
    background-color: var(--primary);
    height: 2px;
  }

  .header {
    display: flex;
    border-bottom: 2px solid var(--primary);
    justify-content: space-between;

    * {
      padding: 0;
      font-size: 18px;
    }

  }

  .resetButton {
    text-decoration: underline;
    padding: 0;
    font-size: 16px;
    margin-left: auto;
  }
}
