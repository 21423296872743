.audit-new__spinner-wrap{
  display: flex;
  justify-content: center;
}

.audit-new {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  max-width: 500px;

  form{
    width: 100%;
  }

  .searchable-dropdown {
    margin: 20px 0;
    max-width: 400px;
  }

  .audit-new__result {

    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;

    > * {
      margin: 10px 0;
      width: 100%;
    }

    h3 {
      text-align: center;
    }

    ul {
      background-color: var(--background-grey);
      padding: 20px;
    }

    button {
      width: 100%;
    }

    svg {
      font-size: 150px;

      &.audit-new__result__success-icon {
        color: #64A70B;
      }

      &.audit-new__result__failure-icon {
        color: var(--red);
      }
    }
  }
  .audit-new__audit-owner{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    .audit-new__audit-owner__cb{
      margin-bottom: 25px;
    }
    form {
      width: 100%;
    }
  }

}

.audit-doc-upload{
  .audit-doc-upload__form{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    > * {
      margin-bottom: 30px;
    }
  }
  .audit-doc-upload__success{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    > * {
      margin-bottom: 20px;
    }
    > :first-child{
      margin-bottom: 80px;
    }
  }
}
