.pageHeader {
  display: flex;
  border-bottom: solid 2px;
  border-bottom-color: var(--primary);
  margin-bottom: 20px;
  align-items: center;

  h1 {
    padding-left: 20px;
  }

  .separator {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    color: var(--disabled);
  }

  .additionalInfo {
    color: var(--grey);
  }

  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.menuItem {
  font-size: 16px !important;
  color: var(--grey) !important;

  &:hover {
    text-decoration: none !important;
  }

  &:focus, &:active {
    color: var(--grey) !important;
  }
}

.menu-items {
  a[role='menuitem'] {
    width: 100% !important;

    div[role='presentation'] {
      width: 100% !important;
    }
  }
}

