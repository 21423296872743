.dashboardContainer {
  height: 720px;
}
.dashboardSelector {
  margin-bottom: 30px;
}
.signin{
  display: flex;
  flex-direction: column;
  label{
    display: flex;
    justify-content: space-between;
  }
  button{
    max-width: 200px;
    align-self: center;
    margin-top: 15px;
  }
}
