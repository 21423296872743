.main {
  h2, .secondaryHeader{
    display: flex;
    align-items: center;
  }
  h2{
    font-weight: normal;
    font-size: 1.25rem;
    min-width: 300px;
    margin: 0 30px 0 0;
  }
  .secondaryHeader {
    color: var(--grey);
    margin: 0;
    &.revision{
      color: var(--primary);
    }
    &.valid {
      color: var(--green);
    }
    &.notValid {
      color: var(--red);
    }
  }
}
