$spinner-size: 80px;
.spinner-placeholder {
  width: $spinner-size;
  height: $spinner-size;
}
.spinner {
  display: inline-block;
  position: relative;
  width: $spinner-size;
  height: $spinner-size;

  div{
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 8px;
    border: 6px solid var(--primary);
    border-radius: 50%;
    animation: spinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

   &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerCentered{
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 100px;
}
