.finalRow {
  display: flex;

  p {
    margin: 0;
    display: flex;
    align-items: center
  }

  .addRow {
    margin-left: auto;
  }
}
