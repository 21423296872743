.star-rating {
  display: flex;
  label{
    color: var(--primary);
    font-size: 1.5625rem;
  }
  span.MuiRating-iconEmpty {
    color: var(--primary);
  }
  span.MuiRating-icon {

    color: var(--primary);
  }
}
