.button {
  background: none;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
}
