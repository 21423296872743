.duoSpacing {
  width: 100%;
  display: flex;
  flex: 1;
  > :first-child{
    margin-right: 10px
  }
  > :last-child{
    margin-left: 10px;
  }
}
