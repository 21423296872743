.sharingCell{
  width: 40px;
  height: 40px;

  .spinner{
    width: 80%;
    height: 80%;
    div{
      width: 100%;
      height: 100%;
    }
  }
   > * {
     width: 100%;
     height: 100%;
   }
}
