.notification-group {
  margin-bottom: 30px;
  width: 100%;
  max-width: 600px;

  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0 !important;
    width: 100%;
    justify-content: space-between;
  }
}
